/* eslint-disable import/first */
import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate,
    Navigate,
    Outlet,
} from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import {
    AuthProvider,
    TabProvider,
    LangProvider,
    CartProvider,
    CombinedFilterProvider,
    AuthContext,
} from './context/AppContext';
import AllContext from './context/AllContext';
import './App.css';
import { CircularProgress } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';

// test
import Test from './pages/Test/test';
import Success from './pages/Payment/Success';
import Fail from './pages/Payment/Fail';

//api
import { api_seo } from './api/page';
import { get_token } from './api/auth';
import { getToken } from './localstorage';
import { api_get_favicon } from './api/hospital';
import NearestLocationInitializer from './hooks/initializeNearestLocation';
import IdleTimeout from './components/IdleTimeout';
import WhoWeAre from './pages/WhoWeAre/WhoWeAre';
import useThirdPartyScript from './hooks/useThirdPartyScript';
import { InnovationCampaign } from './pages/InnovationCampaign';

//lazy load page components
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Home = lazy(() => import('./pages/Home/Home/Home'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const OurHospitalKpj = lazy(() => import('./pages/OurHospitalKpj/OurHospitalKpj'));
const FindADoctorsKpj = lazy(() => import('./pages/FindADoctorsKpj/FindADoctorsKpj'));
const OurSpecialityCentreKpj = lazy(() =>
    import('./pages/OurSpecialityCentreKpj/OurSpecialityCentreKpj')
);
const IndividualDoctorKpj = lazy(() =>
    import('./pages/IndividualDoctorKpj/IndividualDoctorKpj')
);
const IndividualHospitalKpj = lazy(() =>
    import('./pages/IndividualHospitalKpj/IndividualHospitalKpj')
);
const IndividualHealthPackageKpj = lazy(() =>
    import('./pages/IndividualHealthPackageKpj')
);
const ProfilePageKpj = lazy(() => import('./pages/ProfilePageKpj'));
const HealthPackagesKpj = lazy(() =>
    import('./pages/HealthPackagesKpj/HealthPackagesKpj')
);
const MyCollectionsHealthPackage = lazy(() =>
    import('./pages/MyCollectionsHealthPackage/index')
);
const MyCollectionsDoctor = lazy(() => import('./pages/MyCollectionsDoctor/index'));
const MyCollectionsArticlesKpj = lazy(() =>
    import('./pages/MyCollectionsArticlesKpj/MyCollectionsArticlesKpj')
);
const MyCollectionsPodcastKpj = lazy(() =>
    import('./pages/MyCollectionsPodcastKpj/MyCollectionsPodcastKpj')
);
const MyCollectionsVideosKpj = lazy(() =>
    import('./pages/MyCollectionsVideosKpj/MyCollectionsVideosKpj')
);
const HealthTourismKPJ = lazy(() => import('./pages/HealthTourism/HealthTourismKPJ'));
const InternationalPatientCentreKPJ = lazy(() =>
    import('./pages/InternationalPatientCentreKPJ/InternationalPatientCentreKPJ')
);
const CaresKPJ = lazy(() => import('./pages/CaresKPJ/CaresKPJ'));
const CampaignsPromotions = lazy(() =>
    import('./pages/CampaignsPromotions/CampaignsPromotions')
);
const IndividualCampaignsPromotions = lazy(() =>
    import('./pages/IndividualCampaignsPromotions')
);
const CaresKPJApp = lazy(() => import('./pages/CaresKPJApp/CaresKPJApp'));
const CaresKPJAppTermsAndCondition = lazy(() =>
    import('./pages/CaresKPJApp/TermsAndCondition')
);
const CaresKPJAppPrivacyPolicy = lazy(() => import('./pages/CaresKPJApp/PrivacyPolicy'));
const CaresKPJAppAccountDeletionGuideline = lazy(() =>
    import('./pages/CaresKPJApp/AccountDeletionGuideline')
);
const CaresKPJAppFaq = lazy(() => import('./pages/CaresKPJApp/FAQ'));
const CaresKPJAppPrivacyPolicyAndAccountDeletionGuidelines = lazy(() =>
    import('./pages/CaresKPJApp/PrivacyPolicyAndAccountDeletionGuideline')
);

const Video = lazy(() => import('./pages/Video/Video'));
const IndividualSpecialistKpj = lazy(() =>
    import('./pages/EyeSpecialistKPJ/IndividualSpecialistKpj')
);
const InsightsCornerKPJ = lazy(() =>
    import('./pages/InsightsCornerKPJ/InsightsCornerKPJ')
);
const ContactUsKPJ = lazy(() => import('./pages/ContactUsKPJ/ContactUsKPJ'));
const IndividualVideo = lazy(() => import('./pages/IndividualVideo'));
const Podcast = lazy(() => import('./pages/Podcast'));
const IndividualPodcast = lazy(() => import('./pages/IndividualPodcast'));
const Articles = lazy(() => import('./pages/Articles'));
const IndividualArticle = lazy(() => import('./pages/IndividualArticle'));
const Events = lazy(() => import('./pages/Events'));
const IndividualEvent = lazy(() => import('./pages/IndividualEvent'));
const SymtonCheck = lazy(() => import('./pages/SymtonCheck'));
const FrequentlyAskedQuestionsKPJ = lazy(() =>
    import('./pages/FrequentlyAskedQuestionsKPJ/FrequentlyAskedQuestionsKPJ')
);
const TeleconsultKPJ = lazy(() => import('./pages/TeleconsultKPJ/TeleconsultKPJ'));
const MyCartKPJ = lazy(() => import('./pages/MyCartKPJ/MyCartKPJ'));
const AboutKPJ = lazy(() => import('./pages/AboutKPJ/AboutKPJ'));
const SettingKPJ = lazy(() => import('./pages/SettingKPJ/SettingKPJ'));
const HomeHeader = lazy(() => import('./pages/Home/Home/HomeHeader/HomeHeader'));
const HomeKpjFooter = lazy(() => import('./pages/Home/Home/HomeKpjFooter/HomeKpjFooter'));
const MyPurchasesKPJ = lazy(() => import('./pages/MyPurchasesKPJ/MyPurchasesKPJ'));
const SearchKPJ = lazy(() => import('./pages/SearchKPJ/SearchKPJ'));
const ServiceKPJ = lazy(() => import('./pages/ServiceKPJ/ServiceKPJ'));
const HistoryKPJ = lazy(() => import('./pages/HistoryKPJ/HistoryKPJ'));
const PatientCareKPJ = lazy(() => import('./pages/PatientCareKPJ/PatientCareKPJ'));
const IndividualSpecialistService = lazy(() =>
    import('./pages/IndividualSpecialistService/IndividualSpecialistService')
);
const Receipt = lazy(() => import('./pages/MyPurchasesKPJ/Receipt'));
const Tender = lazy(() => import('./pages/Tender/Tender'));
const Career = lazy(() => import('./pages/Career/Career'));
const CareerV2 = lazy(() => import('./pages/Career/CareerV2'));
const News = lazy(() => import('./pages/News/News'));
const MembershipTierRanking = lazy(() =>
    import('./pages/CaresKPJ/MembershipTierRanking')
);
const CaresTermsAndConditions = lazy(() => import('./pages/CaresKPJ/TermsAndCondition'));
const CaresFAQ = lazy(() => import('./pages/CaresKPJ/FAQ'));
const CaresPlatinumPlus = lazy(() => import('./pages/CaresKPJ/PlatinumPlus'));
const IndividualMedicalService = lazy(() =>
    import('./pages/IndividualHospitalKpj/IndividualMedicalService')
);

const Consultant = lazy(() => import('./pages/Consultant'));

const GlApplication = lazy(() => import('./pages/GlApplication'));
const AmbulatoryCareAndWellness = lazy(() => import('./pages/AmbulatoryCareAndWellness'));
const KlinikWaqaf = lazy(() => import('./pages/KlinikWaqaf'));

const AnciliaryServices = lazy(() => import('./pages/AnciliaryServices'));
const PDPA = lazy(() => import('./pages/PDPA'));
const CSR = lazy(() => import('./pages/CSR/SocialRes'));
const IndividualCSR = lazy(() => import('./pages/IndividualCSR/IndividualCsr'));

const CorporateHome = lazy(() => import('./pages/CorporateHome/CorporateHome'));

import OurCollaboration from './pages/MayoClinic/OurCollaboration';
import MayoFaq from './pages/MayoClinic/Faq';
import MayoHealthLibrary from './pages/MayoClinic/HealthLibrary';
import NotFoundComponent from './pages/NotFound/NotFoundComponent';
import { Helmet } from 'react-helmet';

const HealthInformation = lazy(() => import('./pages/HealthInformation'));
const Diseases = lazy(() => import('./pages/HealthInformation/Diseases'));
const FirstAid = lazy(() => import('./pages/HealthInformation/FirstAid'));
const Symptoms = lazy(() => import('./pages/HealthInformation/Symptoms'));
const TestsProcedures = lazy(() => import('./pages/HealthInformation/TestsProcedures'));
const FAQ = lazy(() => import('./pages/HealthInformation/Faq'));
const HealthInformationDetails = lazy(() => import('./pages/HealthInformation/Details'));

const AppRoutes = () => {
    const [seo_info, setSeoInfo] = useState(null);
    const [_token, setToken] = useState(getToken());

    const navigate = useNavigate();
    const location = useLocation();

    const { fetchThirdPartyScript } = useThirdPartyScript();

    useEffect(() => {
        fetchThirdPartyScript({});
    }, []);

    // for seo url usage
    const componentMap = {
        doctor: IndividualDoctorKpj, // checked on 24/11/2023, works
        hospital: IndividualHospitalKpj, // checked on 24/11/2023, works
        'speciality-centre': IndividualSpecialistKpj, // checked on 24/11/2023, works
        'speciality-centre-service': IndividualSpecialistService, // checked on 24/11/2023, works
        'center-of-excellence': IndividualMedicalService, // checked on 24/11/2023, works
        'health-package': IndividualHealthPackageKpj, // checked on 24/11/2023, works
        events: IndividualEvent, // checked on 24/11/2023, works
        'campaigns-promotions': IndividualCampaignsPromotions, // checked on 24/11/2023, works
        articles: IndividualArticle, // checked on 24/11/2023, works
        video: IndividualVideo, // checked on 24/11/2023, works
        podcast: IndividualPodcast, // checked on 24/11/2023, works
    };

    // function to change favicon
    const changeFavicon = (rel, src) => {
        var favicon = document.querySelector('link[rel="' + rel + '"]');
        if (favicon) {
            favicon.href = src;
        } else {
            // If the favicon element doesn't exist, create a new one
            var newFavicon = document.createElement('link');
            newFavicon.rel = rel;
            newFavicon.href = src;

            // Append the new favicon element to the head
            document.head.appendChild(newFavicon);
        }
        // trigger change by changing title
        const default_title = document.title;
        document.title = 'KPJ';
        document.title = default_title;
    };

    const checkTokenValidity = async () => {
        try {
            return get_token('check_token', _token)
                .then((data) => {
                    return data.status !== false;
                })
                .catch((error) => {
                    return false;
                });
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        let isMounted = true;
        let token_recreate = _token || null;
        checkTokenValidity()
            .then((isValid) => {
                if (isMounted) {
                    if (!isValid) {
                        get_token('get_token')
                            .then((data) => {
                                localStorage.setItem('token', data.token);
                                setToken(data.token);
                                token_recreate = data.token;
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.log('get_token API error:', error);
                            });
                    }
                }
            })
            .catch((error) => {
                console.log('Check token API error:', error);
            });

        if (_token) {
            let _type = 'keyword'; // keyword or module, keyword means seo url, module means /:module/:id
            api_seo(location.pathname, _token, _type)
                .then((response) => {
                    if (response.status) {
                        if (_type == 'module') {
                            // navigate(response.data.keyword);
                        }
                        setSeoInfo({
                            url: response.data.url,
                            keyword: location.pathname,
                            component: response.data.component,
                            id: response.data.id,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // get favicon
            api_get_favicon()
                .then((res) => {
                    if (res.status) {
                        changeFavicon('icon', res?.data?.favicon);
                        changeFavicon('apple-touch-icon', res?.data?.favicon);
                        changeFavicon('mask-icon', res?.data?.favicon);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        return () => {
            isMounted = false;
            setSeoInfo(null);
        };
    }, [location.pathname, _token]);

    // Helper component to handle dynamic redirection
    const RedirectWithoutTrailingSlash = () => {
        const { pathname } = useLocation();
        // Remove the trailing slash from the pathname
        const newPath = pathname.replace(/\/$/, '');
        console.log({ newPath });
        console.log({ pathname });
        if (pathname !== newPath) {
            console.log(`diff, redirect to ${newPath}`);
            return <Navigate to={newPath} replace />;
        }
        console.log('same');
        if (pathname !== '/') {
            console.log('Redirecting to home');
            return <Navigate to='/' replace />;
        }
        return null;
    };

    // clinic different
    if (seo_info?.component == 'clinic') {
        return <Navigate to={seo_info?.url} replace />;
    }

    const DynamicComponent = componentMap[seo_info?.component];
    return seo_info && DynamicComponent ? (
        <Routes>
            <Route
                path={seo_info.keyword}
                element={
                    <DynamicComponent
                        _id={seo_info.id}
                        _hosp={seo_info?.hosp}
                        _slug={
                            seo_info.keyword.startsWith('/')
                                ? seo_info.keyword.substring(1)
                                : seo_info.keyword
                        }
                    />
                }
            />
        </Routes>
    ) : (
        <Routes>
            <Route path='/home' element={<Home />} />

            {/* hospital */}
            <Route path='/our-hospitals' element={<OurHospitalKpj />} />
            {/* <Route path="/:hospitalSlug" element={<IndividualHospitalKpj />} /> */}

            <Route
                path='/:hospitalSlug/:medicalSlug'
                element={<IndividualMedicalService />}
            />
            <Route path='/:hospitalSlug/our-services' element={<ServiceKPJ />} />
            <Route path='/:hospitalSlug/patient-care' element={<PatientCareKPJ />} />

            {/* doctors */}
            <Route path='/doctor' element={<FindADoctorsKpj />} />
            {/* <Route path='/doctor/:id' element={<IndividualDoctorKpj />} /> */}

            {/* health packages */}
            <Route path='/health-package' element={<HealthPackagesKpj />} />
            {/* <Route path='/health-package/:id' element={<IndividualHealthPackageKpj />} /> */}

            {/* profile */}
            <Route path='/profile' element={<ProfilePageKpj />} />
            <Route
                path='/my-collections-health-package'
                element={<MyCollectionsHealthPackage />}
            />
            <Route path='/my-collections-doctor' element={<MyCollectionsDoctor />} />
            <Route
                path='/my-collections-article'
                element={<MyCollectionsArticlesKpj />}
            />
            <Route path='/my-collections-podcast' element={<MyCollectionsPodcastKpj />} />
            <Route path='/my-collections-video' element={<MyCollectionsVideosKpj />} />
            <Route path='/history' element={<HistoryKPJ />} />
            {/* <Route path='/purchases' element={<MyPurchasesKPJ />} /> */}
            {/* <Route path="/cart" element={<MyCartKPJ />} /> */}
            <Route path='/setting' element={<SettingKPJ />} />

            {/* service */}
            <Route path='/speciality-centre' element={<OurSpecialityCentreKpj />} />
            <Route
                path='/speciality-centre/:id/:clinic'
                element={<IndividualSpecialistKpj />}
            />
            <Route
                path='/speciality-centre/:specialtySlug/:clinicSlug/services'
                element={<IndividualSpecialistService />}
            />

            {/* campro */}
            <Route path='/campaigns-promotions' element={<CampaignsPromotions />} />
            {/* <Route path='/campaigns-promotions/:id' element={<IndividualCampaignsPromotions />} /> */}

            {/* videos */}
            <Route path='/video' element={<Video />} />
            {/* <Route path='/video/:id' element={<IndividualVideo />} /> */}

            {/* podcast */}
            <Route path='/podcast' element={<Podcast />} />
            {/* <Route path='/podcast/:id' element={<IndividualPodcast />} /> */}

            {/* articles */}
            <Route path='/articles' element={<Articles />} />
            {/* <Route path='/articles/:id' element={<IndividualArticle />} /> */}

            {/* event */}
            <Route path='/events' element={<Events />} />
            {/* <Route path='/events/:id' element={<IndividualEvent />} /> */}

            {/* kpj cares */}
            <Route path='/cares' element={<CaresKPJ />} />
            <Route path='/kpj-cares-card-program' element={<CaresKPJ />} />
            <Route path='/cares-membership' element={<MembershipTierRanking />} />
            <Route path='/cares-platinum-plus' element={<CaresPlatinumPlus />} />

            <Route path='/cares-app' element={<CaresKPJApp />} />
            <Route
                path='/cares-app/terms-and-conditions'
                element={<CaresKPJAppTermsAndCondition />}
            />
            {/* <Route path="/cares-app/privacy-policy" element={<CaresKPJAppPrivacyPolicy />} /> */}
            {/* <Route path="/cares-app/account-deletion-guidelines" element={<CaresKPJAppAccountDeletionGuideline />} /> */}
            <Route
                path='/cares-app/privacy-policy-and-account-deletion-guidelines'
                element={<CaresKPJAppPrivacyPolicyAndAccountDeletionGuidelines />}
            />
            <Route path='/cares-app/faq' element={<CaresKPJAppFaq />} />

            <Route path='/cares-terms' element={<CaresTermsAndConditions />} />
            <Route path='/cares-faq' element={<CaresFAQ />} />

            <Route path='/health-tourism' element={<HealthTourismKPJ />} />
            <Route
                path='/international-patient-centre'
                element={<InternationalPatientCentreKPJ />}
            />

            <Route path='/insights-corner' element={<InsightsCornerKPJ />} />
            <Route path='/contact-us' element={<ContactUsKPJ />} />

            <Route path='/faq' element={<FrequentlyAskedQuestionsKPJ />} />
            {/* <Route path="/teleconsult" element={<TeleconsultKPJ />} /> */}

            {/* tender */}
            <Route path='/procurement' element={<Tender />} />
            {/* <Route path='/tender' element={<Tender />} /> */}

            {/* career */}
            {/* <Route path="/career-v1" element={<Career />} /> */}
            <Route path='/career' element={<CareerV2 />} />

            {/* news */}
            <Route path='/news' element={<News />} />
            <Route path='*' element={<Home />} />
            <Route path='/' element={<Home />} />

            {/* below not implement react lazy load */}
            {/* <Route path='/about' element={<AboutKPJ />} /> */}
            <Route path='/search' element={<SearchKPJ />} />
            {/* <Route path='/symptom-check' element={<SymtonCheck />} /> */}
            {/* <Route path='/contact' element={<Contact />} /> */}

            {/* payment */}
            {/* <Route path='/payment-success' element={<Success />} /> */}
            {/* <Route path='/payment-fail' element={<Fail />} /> */}
            {/* <Route path="/receipt/:id" element={<Receipt />} /> */}
            {/* <Route path="/not-match" element={<NotFound />} /> */}

            <Route path='/consultant' element={<Consultant />} />

            <Route path='/gl-application' element={<GlApplication />} />

            <Route
                path='/ambulatory-care-and-wellness'
                element={<AmbulatoryCareAndWellness />}
            />

            <Route path='/klinik-wakaf-an-nur' element={<KlinikWaqaf />} />

            <Route path='/anciliary-services' element={<AnciliaryServices />} />
            <Route path='/pdpa' element={<PDPA />} />

            <Route path='/csr' element={<CSR />} />
            <Route path='/csr/:id' element={<IndividualCSR />} />

            {/* Mayo Clinic routes */}

            {/* <Route path='/mccn/our-collaboration' element={<OurCollaboration />} noCache />
            <Route path='/mccn/faq' element={<MayoFaq />} noCache />
            <Route path='/mccn/health-library' element={<MayoHealthLibrary />} noCache />

            <Route path='/mccn' element={<HealthInformation />}>
                <Route path='diseases' element={<Diseases />} />
                <Route path='diseases/:id' element={<HealthInformationDetails />} />
                <Route path='first-aid' element={<FirstAid />} />
                <Route path='first-aid/:id' element={<HealthInformationDetails />} />
                <Route path='symptoms' element={<Symptoms />} />
                <Route path='symptoms/:id' element={<HealthInformationDetails />} />
                <Route path='tests-procedures' element={<TestsProcedures />} />
                <Route
                    path='tests-procedures/:id'
                    element={<HealthInformationDetails />}
                />
                <Route path='faqs' element={<FAQ />} />
                <Route path='faqs/:id' element={<HealthInformationDetails />} />

                <Route path='' element={<Navigate to="/mccn/our-collaboration" />} replace />
                <Route path='*' element={<Navigate to="/mccn/our-collaboration" />} replace />
            </Route> */}

            <Route path='/innovationcampaign' element={<InnovationCampaign />} noCache />

            {/* investor */}
            <Route path='/corporate-home' element={<CorporateHome />} />
            {/* <Route path='/who-we-are' element={<WhoWeAre />} /> */}

            {/* <Route path='/innovationcampaign' element={<InnovationCampaign />} /> */}

            {/* to handle those link from google search, example: ipoh/ */}
            {/* <Route path="/:slug/" element={<RedirectWithoutTrailingSlash />} /> */}
        </Routes>
    );
};

const App = () => {
    const userIsAuthenticated = isAuthenticated();

    return (
        <Suspense
            fallback={
                <div className='loading-container'>
                    <CircularProgress />
                </div>
            }>
            <AllContext>
                <BrowserRouter>
                    <ScrollTop />
                    <CartProvider>
                        <AuthProvider>
                            <TabProvider>
                                <LangProvider>
                                    <CombinedFilterProvider>
                                        <HomeHeader />
                                        <IdleTimeout />
                                        <NearestLocationInitializer />
                                        {/* {userIsAuthenticated && <AppRoutes />} */}
                                        <AppRoutes />
                                        <HomeKpjFooter />
                                    </CombinedFilterProvider>
                                </LangProvider>
                            </TabProvider>
                        </AuthProvider>
                    </CartProvider>
                </BrowserRouter>
            </AllContext>
        </Suspense>
    );
};

const isAuthenticated = () => {
    const userId = localStorage.getItem('user_id');
    return userId !== null;
};

const PrivateRoute = ({ children }) => {
    const userIsAuthenticated = isAuthenticated();

    if (userIsAuthenticated) {
        return children;
    }

    return <Link to='/login' />;
};

export default App;
